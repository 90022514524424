import "./floatWpp.css"
import {RiWhatsappFill} from 'react-icons/ri'
export default function Whatsapp(){
        return (
            <div className="wpp">
                <a className="wppLink" href="https://wa.me/557791894228">
                    <RiWhatsappFill size={85}></RiWhatsappFill>
                </a>
            </div>
        )
}